import axios from 'axios';

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {


    //******************************************** Authentications Info ********************************************

    IsLoggedin() {
        return axios.get('/Security/IsLoggedin');
    },

    Login(bodyObject) {
        return axios.post('/Security/Login', bodyObject, { withCredentials: true });
    },


    RequestCahngeDevice(bodyObject) {
        return axios.post('/Security/RequestCahngeDevice', bodyObject, { withCredentials: true });
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },

    //******************************************** User Info ********************************************

    ChangeProfilePicture(bodyObject) {
        return axios.post('/Api/Admin/User/ChangeProfilePicture', bodyObject);
    },

    EditPersonalInfo(bodyObject) {
        return axios.post('/Api/Admin/User/EditPersonalInfo', bodyObject);
    },

    EditContactInfo(bodyObject) {
        return axios.post('/Api/Admin/User/EditContactInfo', bodyObject);
    },

    ChangePassword(bodyObject) {
        return axios.post(`/Api/Admin/User/ChangePassword`, bodyObject);
    },

    AboutMeInfo(bodyObject) {
        return axios.post(`/Api/Admin/User/AboutMeInfo`, bodyObject);
    },


    //******************************************** Student Info ********************************************
    RegesterStudent(bodyObject) {
        return axios.post('/Api/Admin/Students/Regester', bodyObject);
    },

    GetStudentFullInfo() {
        return axios.get(`/Api/Admin/Students/GetFullInfo`);
    },

    GetCourseLecture(CourseId) {
        return axios.get(`/Api/Admin/Students/GetStudentCourseLecture?CourseId=${CourseId}`);
    },

    CourseLectur(Id) {
        return axios.get(`/Api/Admin/Students/CourseLecture?Id=${Id}`);
    },

    Rate(bodyObject) {
        return axios.post(`/Api/Admin/Students/Rate`, bodyObject);
    },

    ChangeDeviceRequest() {
        return axios.post(`/Api/Admin/Students/ChangeDeviceRequest`);
    },

    RequestRegester(Id) {
        return axios.post(`api/admin/Students/${Id}/RequestRegesterToCourse`);
    },

    SubmitExam(bodyObjeect) {
        return axios.post(`/Api/Admin/Students/InrollExam`, bodyObjeect);
    },


    //******************************************** Fince Student Info ********************************************
    Checkout(bodyObjeect) {
        return axios.post(`/Api/Admin/Students/Checkout`, bodyObjeect);
    },

    Charge(bodyObjeect) {
        return axios.post(`/Api/Admin/Students/Charge`, bodyObjeect);
    },

    //******************************************** Academic Specialization Info ********************************************

    GetAcademicLevelsName() {
        return axios.get(`/Api/Admin/Helper/GetAcademicLevelsName`);
    },

    GetAcademicSpecializationName(id) {
        return axios.get(`/Api/Admin/Helper/GetAcademicSpecializationName?id=${id}`);
    },

    GetAcademicSpecializationInfo() {
        return axios.get(`/Api/Admin/Helper/GetAcademicSpecializationInfo`);
    },



    //******************************************** Course Info ********************************************

    GetCoursesInfo(pageNo, pageSize, AcademicLevelId, AcademicSpecializationId, InstructorId, SubjectId, Type) {
        return axios.get(`/Api/Admin/Helper/GetCoursesInfo?pageNo=${pageNo}&pagesize=${pageSize}&AcademicLevelId=${AcademicLevelId}&AcademicSpecializationId=${AcademicSpecializationId}&InstructorId=${InstructorId}&SubjectId=${SubjectId}&Type=${Type}`);
    },

    GetCoursesInfoOrderByTop(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Helper/GetCoursesInfoOrderByTop?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    GetCourseInfoById(CourseId) {
        return axios.get(`/Api/Admin/Helper/GetCourseById?CourseId=${CourseId}`);
    },

    GetCourseInfo(CourseId) {
        return axios.get(`/Api/Admin/Helper/GetCoursesFullInfo?CourseId=${CourseId}`);
    },


    //******************************************** Subject Info ********************************************

    GetSupjectName() {
        return axios.get(`/Api/Admin/Helper/GetSupjectName`);
    },

    //******************************************** Instructor Info ********************************************

    GetInstructorsName() {
        return axios.get(`/Api/Admin/Helper/GetInstructorsName`);
    },

    GetInstructors(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Helper/GetInstructors?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    GetInstructorsById(Id) {
        return axios.get(`/Api/Admin/Helper/GetInstructorsById?Id=${Id}`);
    },


    //******************************************** Countact Us ********************************************
    CountactUs(bodyObjeect) {
        return axios.post(`/Api/Admin/Helper/CountactUs`, bodyObjeect);
    },

}