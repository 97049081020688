import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: 'Sinup',
    mixins: [HelperMixin],
    components: {
    },
     async created() {
        window.scrollTo(0, 0);
        await this.GetAcademicLevels();
    },
    data() {
        var validatePass = (rules, value, callback) => {
            if (value === '') {
                callback(new Error('الرجاء إدخال كلمة المرور'));
            } else {
                if (this.ruleForm.ConfimPassword !== '') {
                    this.$refs.ruleForm.validateField('ConfimPassword');
                }
                callback();
            }
        };
        var validatePass2 = (rrulesule, value, callback) => {
            if (value === '') {
                callback(new Error('الرجاء كتابه اعاده كلمه المرور'));
            } else if (value !== this.ruleForm.Password) {
                callback(new Error('الرجاء التأكد من تطابق كلمة المرور'));
            } else {
                callback();
            }
        };
        return {


            ruleForm: {
                
                FirstName: '',
                FatherName: '',
                SirName: '',
                LoginName: '',
                Password: '',
                ConfirmPassword: '',
                CollageId: '',
                Phone: '',
                Email: '',
                Gender: '',
                AcademicLevelId: '',
                AcademicSpecializationId: '',
                AboutMe: '',
            },
            rules: {
                FirstName: this.$helper.DynamicArabicEnterRequired('الاسم '),
                FatherName: this.$helper.DynamicArabicEnterRequired('اسم الاب '),
                SirName: this.$helper.DynamicArabicEnterRequired('اللقب '),
                LoginName: [
                    { required: true, message: 'الرجاء إدخال اسم الدخول', trigger: 'blur' },
                    { required: true, pattern: /^[A-Za-z]{0,40}$/, message: 'يجب ان يكون بدون فراغات واشارات (mbashier)', trigger: 'blur' }
                ],
                Password: [
                    { validator: validatePass, trigger: 'blur' },
                    { required: true, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}.*$/, message: '  تتكون كلمة المرور من حروف صغيرة وكبيرو وأرقام', trigger: 'blur' }
                ],
                ConfirmPassword: [
                    { validator: validatePass2, trigger: 'blur' },
                    { required: true, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}.*$/, message: ' تتكون كلمة المرور من حروف صغيرة وكبيرو وأرقام', trigger: 'blur' }
                ],
                CollageId: [
                    {
                        required: true,
                        message: "الرجاء إدخال رقـم القيد",
                        trigger: "blur",
                    },
                    {
                        min: 2,
                        max: 20,
                        message: "يجب ان يكون طول رقم القيد من 2 الي 20 الحرف",
                        trigger: "blur",
                    },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' }
                ],
                Phone: [
                    {
                        required: true,
                        message: "الرجاء إدخال رقم الهاتف",
                        trigger: "blur",
                    },
                    {
                        min: 9,
                        max: 13,
                        message: "يجب ان يكون طول رقم الهاتف 9 ارقام علي الاقل",
                        trigger: "blur",
                    },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' }
                ],
                Email: [
                    { required: true, message: 'الرجاء إدخال البريد الإلكتروني', trigger: 'blur' },
                    { required: true, pattern: /\S+@\S+\.\S+/, message: 'الرجاء إدخال البريد الإلكتروني بطريقه صحيحه', trigger: 'blur' }
                ],
                Gender: this.$helper.Required('الجنس '),
                AcademicLevelId: this.$helper.Required('المرحلة التعليمية'),
                AcademicSpecializationId: this.$helper.Required('التخصص'),
            },


        };
    },
    methods: {

        href(url) {
            this.$router.push(url);
        },

        async GetAcademicSpecializationName() {
            this.ruleForm.AcademicSpecializationId = '',
                await this.GetAcademicSpecialization(this.ruleForm.AcademicLevelId)
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    if (this.ruleForm.Password != this.ruleForm.ConfirmPassword) {
                        this.$helper.ShowMessage('error', 'خطأ بالعملية', 'الرجاء التأكد من تطابق كلمة المرور');
                        return;
                    } else {
                        this.$blockUI.Start();
                        this.$http.RegesterStudent(this.ruleForm)
                            .then(response => {
                                this.$blockUI.Stop();
                                this.resetForm(formName);
                                this.$helper.ShowMessage('success', ' تمت عملية التسجيل بنجاح', response.data);
                                setTimeout(() => window.location.href = "/CommingSoon", 1000);
                            })
                            .catch((err) => {
                                this.$blockUI.Stop();
                                this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                            });
                    }

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    }
}
