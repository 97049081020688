import { render, staticRenderFns } from "./Courses.html?vue&type=template&id=e27253e6&scoped=true&external"
import script from "./Courses.js?vue&type=script&lang=js&external"
export * from "./Courses.js?vue&type=script&lang=js&external"
import style0 from "./Courses.vue?vue&type=style&index=0&id=e27253e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e27253e6",
  null
  
)

export default component.exports